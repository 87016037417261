<template lang="pug">
v-card
  v-card-head
    v-card-head-label
      v-card-head-label
        h2.title {{ 'pages.settings.email_settings' | translate }}
        p.subtitle {{ 'infotext.settings_email_subtitle' | translate }}
  v-card-content
    .email-settings--table
      .email-settings--table__header
        h4(style="width: 320px") Email type
        h4(style="width: 160px") Active
        h4(style="width: 270px") Default setting
      .email-settings--table__body(v-if="loaded")
        v-scrollable(:bottom-offset="80")
          .email-settings--table__body--row
            span.email-settings--table__body--row--title(style="width: 320px") {{ 'ui.labels.profile_summaries' | translate }}
              v-info-icon(:text="$t('infotext.profile_summaries_info')")
            div(style="width: 160px")
              v-switch(
                icon,
                noMargin,
                outline,
                :value="emailRemindersSettings.isNewsEmailEnabled",
                @input="changeSetting($event, 'isNewsEmailEnabled')"
              )
            v-select(
              style="width: 270px",
              single,
              track-by="title",
              item-value="value",
              :value="changeTextValueToObjectValue(emailRemindersSettings.emailNewsInterval)",
              :options="emailRemindersPreferencesOptions",
              @input="changeSetting($event, 'emailNewsInterval')",
              isFortable,
              hideBottomMargin,
              fixed
            )
          .email-settings--table__body--row
            span.email-settings--table__body--row--title(style="width: 320px") {{ 'ui.labels.badge_suggestions' | translate }}
              v-info-icon(
                :text="$t('infotext.badge_suggestions_info')"
              )
            div(style="width: 160px")
              v-switch(
                icon,
                noMargin,
                outline,
                :value="emailRemindersSettings.isSuggestionsEmailEnabled",
                @input="changeSetting($event, 'isSuggestionsEmailEnabled')"
              )
            v-select(
              style="width: 270px",
              single,
              track-by="title",
              item-value="value",
              :value="changeTextValueToObjectValue(emailRemindersSettings.emailSuggestionsInterval)",
              :options="emailRemindersPreferencesOptions",
              @input="changeSetting($event, 'emailSuggestionsInterval')",
              isFortable,
              hideBottomMargin,
              fixed
            )
          .email-settings--table__body--row
            span.email-settings--table__body--row--title(style="width: 320px") {{ 'ui.labels.validation_requests' | translate }}
              v-info-icon(
                :text="$t('infotext.validation_requests_info')"
              )
            div(style="width: 160px")
              v-switch(
                icon,
                noMargin,
                outline,
                :value="emailRemindersSettings.isValidationsEmailEnabled",
                @input="changeSetting($event, 'isValidationsEmailEnabled')"
              )
            v-select(
              style="width: 270px",
              single,
              track-by="title",
              item-value="value",
              :value="changeTextValueToObjectValue(emailRemindersSettings.emailValidationsInterval)",
              :options="emailRemindersPreferencesOptions",
              @input="changeSetting($event, 'emailValidationsInterval')",
              isFortable,
              hideBottomMargin,
              fixed
            )
      div(v-else)
        .email-settings--loader__row(v-for="key in [1, 2, 3]", :key="key")
          v-skeleton(width="100%")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { EMAIL_REMINDERS_PREFERENCES_OPTIONS } from '@/util/constants'

export default {
  name: 'EmailSettings',

  async mounted () {
    this.loaded = true
    await this.getEmailRemembersSettings()
  },

  data: () => ({
    loaded: false
  }),
  computed: {
    ...mapGetters('app', ['emailRemindersSettings']),

    emailRemindersPreferencesOptions () {
      return EMAIL_REMINDERS_PREFERENCES_OPTIONS
    }
  },

  methods: {
    ...mapActions('app', [
      'clearErrors',
      'setPopupMessage',
      'getEmailRemembersSettings',
      'setEmailRemembersSettings'
    ]),
    async changeSetting (value, nameKey) {
      await this.setEmailRemembersSettings({ nameKey, value })
    },
    changeTextValueToObjectValue (val) {
     return val !== null ? this.emailRemindersPreferencesOptions.find(el => el.value === val) : null
    }
  }
}
</script>

<style lang="scss" scoped>
.email-settings {
  &--table {
    &__header {
      display: flex;
      padding: 1rem 0px;
      h4 {
        font-weight: 600;
        color: $body-text;
      }
    }

    &__body {
      &--row {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 0.5rem 0px;

        &--title {
          font-size: 1.3rem;
          color: $body-text;
        }
      }
    }
  }
  &--loader__row {
    margin: 15px 0;
  }
}
</style>
